import './index.css';
import 'normalize.css';
import { isRunningInOpenFin } from './host/openfin/OpenFinHelpers';
import WebFont from 'webfontloader';

const init = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isPopup = urlParams.get('popupId') !== null;
    const isSystemTray = urlParams.get('systemTray') !== null;
    if (isPopup) {
        await import(/* webpackChunkName: "popupIndex" */ './popupIndex');
    } else if (isSystemTray) {
        await import(/* webpackChunkName: "systemTrayIndex" */ './system-tray/systemTrayIndex');
    } else {
        // main window
        if (isRunningInOpenFin()) {
            await import(/* webpackChunkName: "start" */ './start');
        } else {
            await import(/* webpackChunkName: "browserStart" */ './browserStart');
        }
        WebFont.load({
            custom: {
                families: ['Lato:n3,n4,n6', 'sans-serif', 'Material Icons'],
            },
        });
    }
};

init();
