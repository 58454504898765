import { fin } from '@openfin/core';
import { Identity, MonitorDetails, MonitorInfo, WindowOptions, Window, Bounds, LegacyWinOptionsInAppOptions } from '@openfin/core/out/GlobalOpenFin';

export const isRunningInOpenFin = () => typeof window !== 'undefined' && 'fin' in window && 'desktop' in fin;

export const getOpenFinChildWindows = async () => {
    const app = await fin.Application.getCurrent();
    return await app.getChildWindows();
};

export const getWindowState = async (win: Window) => {
    return (await win.getState()) as 'minimized' | 'maximized' | 'normal';
};

export const restoreWindowState = async (win: Window) => {
    await win.restore();
};
export const closeAllOpenFinChildWindows = async () => {
    const childWindows = await getOpenFinChildWindows();
    await Promise.all(childWindows.map(x => x.close(true)));
};

export const getOpenFinWindowOptions = async (win: Window) => {
    return (await win.getOptions()) as WindowOptions;
};

export const setOpenFinWindowOptions = async (win: Window, options: LegacyWinOptionsInAppOptions) => {
    await win.updateOptions(options);
};

export const getOpenFinWindowBounds = async (win: Window) => {
    return (await win.getBounds()) as Bounds;
};

export const forceWindowToFront = async (win: Window): Promise<void> => {
    await setOpenFinWindowOptions(win, { alwaysOnTop: true });
    await setOpenFinWindowOptions(win, { alwaysOnTop: false });
};

const APP_UUID = isRunningInOpenFin() ? fin.Application.getCurrentSync().identity.uuid : '';
export const getOpenFinApplicationUUID = () => APP_UUID;

export const closeApplication = () => isRunningInOpenFin() && fin.Application.getCurrentSync().close();

export const getOpenFinCurrentWindow = () => fin.Window.getCurrentSync();

export const getOpenFinWindow = (name: string): Promise<Window> =>
    fin.Window.wrap({ uuid: getOpenFinApplicationUUID(), name });

export const closeWindow = () => isRunningInOpenFin() && getOpenFinCurrentWindow().close();

export const minimizeWindow = () => isRunningInOpenFin() && getOpenFinCurrentWindow().minimize();

export const maximizeWindow = () => isRunningInOpenFin() && getOpenFinCurrentWindow().maximize();

export const restoreWindow = () => isRunningInOpenFin() && getOpenFinCurrentWindow().restore();

export const getMonitorInfo = () => fin.System.getMonitorInfo();

export const getAllMonitorsFromMonitorInfo = (monitorInfo: MonitorInfo) => {
    // we have seen an undefined MonitorDetail get into this array (maybe the primaryMonitor?), so this is now extra cautious

    const allMonitors: MonitorDetails[] = [];
    if (monitorInfo.primaryMonitor) {
        allMonitors.push(monitorInfo.primaryMonitor);
    }
    if (monitorInfo.nonPrimaryMonitors) {
        allMonitors.push(...monitorInfo.nonPrimaryMonitors.filter(monitor => monitor !== undefined));
    }

    return allMonitors;
};

export const getPrimaryMonitor = async (): Promise<MonitorDetails | undefined> => {
    const monitorInfo = await getMonitorInfo();
    const allMonitors = getAllMonitorsFromMonitorInfo(monitorInfo);
    return allMonitors[0];
};

export const getAppManifest = async () => {
    const app = await fin.Application.getCurrent();
    return (await app.getManifest()) as { startup_app: { url: string } };
};

export const getFocusedWindow = async () => {
    if (isRunningInOpenFin()) {
        const focusedWindow = await fin.System.getFocusedWindow();
        return focusedWindow as Identity;
    } else {
        return null;
    }
};
